<template>
    <general-container
        :customClass="'column'"
        :isLocked="false"
    >

        <div class="container">
            <br>
            <h1>Titles of Interest</h1>

            <table class="zebra" v-if="Object.keys(agencies).length > 0">
                <thead>
                <tr>
                    <th>
                        Agency
                    </th>
                    <th>
                        CIO
                    </th>
                    <th>
                        ISM
                    </th>
                    <th>
                        Agency Head
                    </th>
                    <th>
                        Chief of Staff
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(agencyInfo, agencyName) in agencies" :key="agencyName">
                    <td>{{ agencyName }}</td>
                    <td>{{ agencyInfo.CIOName }}</td>
                    <td>{{ agencyInfo.ISMName }}</td>
                    <td>{{ agencyInfo.AgencyHeadName }}</td>
                    <td>{{ agencyInfo.COSName }}</td>
                </tr>
                </tbody>
            </table>
        </div>

    </general-container>
</template>

<script>
    import {computed, ref} from "vue";
    import RequestHandler from "@/handler/RequestHandler";

    export default {
        data() {
            return {
                agencies: []
            };
        },
        methods: {
            loadAgencies() {
                this.agencies = [];
                RequestHandler.loadPrimerAgencies()
                .then(response => {
                    this.agencies = response;
                }).catch(error => {
                    console.error(error);
                });
            },
        },
        mounted() {
            this.loadAgencies();
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";

    .tbl thead th, table thead th {
        color: #fff;
        position: -webkit-sticky;
        position: sticky;
    }
    .documents-table-column {
        padding-left: 0px;
        padding-right: 0px;
    }
</style>